import React from "react";
import { Typography, Button } from "@mui/material";
import { PlaceholderImage } from "@akj-dev/shared-components";
import Main from "../layout/Main";
import header from "../images/header.jpg";

export const Home = () => {
  return (
    <Main title="Hub (Coming Soon)">
      <PlaceholderImage
        src={header}
        title="Hub Header"
        alt="Hub Header"
        width="100%"
      />
      <Typography variant="body1" gutterBottom>
        Affinity Hub is made up of an additional suite of complementary portals,
        apps and APIs that give you the ability to further connect with your
        customers by putting them in greater control of their services and
        options. Your customers will be able to view their bill, monitor usage
        and set bars and alerts all in one place.
      </Typography>
      <Typography variant="h3" gutterBottom>
        Key Features
      </Typography>
      <Typography variant="h6">Self Service</Typography>
      <Typography variant="body1" gutterBottom>
        Portals for your end customers, enabling them to monitor usage, manage
        cost centres and create alerts and bars.
      </Typography>
      <Typography variant="h6">Customisation</Typography>
      <Typography variant="body1" gutterBottom>
        Create & manage your end customer portal instances. Update your brand
        and content through Hub’s built-in CMS.
      </Typography>
      <Typography variant="h6">Connectivity</Typography>
      <Typography variant="body1" gutterBottom>
        Suite of flexible APIs for custom integration.
      </Typography>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        href="https://aurora.io/products/affinity-hub/"
        target="_blank"
      >
        Find out more
      </Button>
    </Main>
  );
};
